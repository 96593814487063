import styled from 'styled-components';

import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const PageWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  background: rgb(${getToken('color-background-primary')});
`;

export const TitleSection = styled.header`
  margin: 40px auto;

  h1 {
    font-size: 20px;
    color: rgb(${getToken('color-success-100')});
  }

  ${screen.lg} {
    h1 {
      font-size: 34px;
    }
  }

  ${screen.xxlg} {
    margin: 50px auto;

    h1 {
      font-size: 38px;
    }
  }
`;

export const ProductsFeatured = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin: 0 auto 40px;

  ${screen.md} {
    margin: 0 auto 60px;
  }

  ${screen.lg} {
    margin: 0 auto 80px;
  }

  ${screen.xxlg} {
    margin: 0 auto 100px;
  }
`;
