import styled from 'styled-components';

import { screen } from '@/helpers/screen';

//components
import { Container } from 'components/Grid';
import { Title, Description } from '@/containers/Hero/Hero.styles';

//icon
import CarrierTMS from '@/public/images/carrier-tms/hero/icon_logo-carriertms-default.svg';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const HeroWrapper = styled.header`
  width: 100%;
  min-height: 1080px;

  background-color: #202931;
  border-bottom: 11px solid transparent;
  border-image: linear-gradient(45.01deg, #00d7d7 23.29%, #84f11e 88.12%);
  border-image-slice: 1;
  box-sizing: content-box;

  svg {
    width: 122px;
    height: auto;
  }

  ${screen.md} {
    min-height: 720px;

    svg {
      width: 190px;
      height: auto;
    }
  }

  ${screen.lg} {
    min-height: 780px;
  }

  ${screen.xxlg} {
    min-height: 810px;
  }
`;

export const HeroContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-top: 60px;

  ${screen.md} {
    max-width: 733px;

    display: grid;
    grid-template-columns: 350px 1fr;
  }

  ${screen.lg} {
    max-width: 1034px;
    padding-top: 80px;

    display: grid;
    grid-template-columns: 570px 1fr;
  }

  ${screen.xxlg} {
    max-width: 1358px;
    grid-template-columns: 730px 1fr;
  }
`;

export const CarrierTMSLogo = styled(CarrierTMS)`
  width: 100%;
  height: 100%;

  ${screen.md} {
    margin-bottom: 50px;
  }

  ${screen.lg} {
    margin-bottom: auto;
  }

  path {
    fill: #fff;
    color: #fff;
  }
`;

export const StyledTitle = styled(Title)`
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};

  margin: 24px 0 0;

  font-size: 24px;
  line-height: 36px;

  text-align: center;

  color: green;
  background: linear-gradient(
    45.01deg,
    rgb(${getToken('color-brand-accent-1')}) 23.29%,
    rgb(${getToken('color-brand-accent-2')}) 88.12%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  ${screen.md} {
    font-size: 27px;
    line-height: 42px;
    text-align: left;
  }

  ${screen.lg} {
    font-size: 48px;
    line-height: 60px;
  }

  ${screen.xxlg} {
    font-size: 58px;
    line-height: 72px;
  }
`;

export const StyledDescription = styled(Description)`
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-regular')};
  color: rgb(${getToken('color-text-inverted')});
  margin: 24px 0 34px;
  font-size: 14px;
  line-height: 22px;
  text-align: center;

  ${screen.md} {
    text-align: left;
    font-size: 16px;
    line-height: 24px;
    margin: 16px 0 26px;
    padding-right: 20px;
  }

  ${screen.lg} {
    margin: 24px 0;
    padding-right: auto;
  }

  ${screen.xxlg} {
    font-size: 20px;
    line-height: 30px;
  }
`;
