import styled from 'styled-components';

import { screen } from '@/helpers/screen';

//components
import { Container } from 'components/Grid';

//icons
import Play from '@/public/images/carrier-tms/video-hero/icon-play.svg';

export const VideoHeroWrapper = styled.section`
  z-index: 2;

  margin-top: -70px;

  ${screen.md} {
    margin-top: -80px;
  }

  ${screen.lg} {
    margin-top: -110px;
  }

  ${screen.xxlg} {
    margin-top: -130px;
  }
`;

export const ContainerStyled = styled(Container)`
  position: relative;

  width: 100%;
  max-width: calc(100% - 38px);

  padding: 0;

  ${screen.md} {
    width: 100%;
    max-width: calc(100% - 168px);
  }

  ${screen.lg} {
    width: 100%;
    max-width: calc(100% - 266px);
  }

  ${screen.xxlg} {
    width: 100%;
    max-width: calc(100% - 686px);
  }
`;

export const Thumb = styled.div`
  position: relative;
`;

export const PlayButton = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 11;

  width: 40px;
  height: 40px;

  transform: translate(-15px, -30px);
  opacity: 0;

  transition: all 0.1s ease-in-out;

  svg,
  path {
    max-width: 100%;
    height: auto;

    color: ${(props) => props.theme.color.primary};
  }

  ${screen.md} {
    width: 60px;
    height: 60px;

    transform: translate(-15px, -40px);
  }

  ${screen.lg} {
    width: 80px;
    height: 80px;

    transform: translate(-35px, -55px);
  }
  ${screen.xxlg} {
    width: 114px;
    height: 114px;

    transform: translate(-45px, -65px);
  }
`;

export const ThumbImage = styled.img`
  max-width: 100%;
  height: auto;
  margin: auto;

  transition: all 0.1s ease-in-out;
`;

export const VideoContainer = styled.div`
  position: relative;

  display: block;
  width: 100%;
  padding: 20px;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */

  background: #000000;

  .react-player {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }
`;

export const VideoWrapper = styled.div`
  position: relative;

  display: flex;
  align-content: center;
  justify-content: center;
  width: 75%;
  height: 100%;
  margin: auto;
`;

export const StopButton = styled(PlayButton)`
  top: 0;
  left: 100%;

  width: 40px;
  height: 40px;
  padding: 10px;

  background: white;
  border-radius: 50%;
  transform: translate(-20px, -5px);
  opacity: 1;

  svg,
  path {
    max-width: 100%;
    height: auto;

    color: ${(props) => props.theme.color.primary};
  }

  ${screen.md} {
    width: 50px;
    height: 50px;

    transform: translate(-25px, -20px);
    opacity: 0;
  }

  ${screen.lg} {
    width: 60px;
    height: 60px;

    transform: translate(-30px, -25px);
  }

  ${screen.xxlg} {
    width: 80px;
    height: 80px;

    padding: 15px;

    transform: translate(-40px, -45px);
  }
`;

export const IconPlay = styled(Play)`
  width: 100%;
  height: 100%;
`;
