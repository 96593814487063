import styled from 'styled-components';

import { carrierOptions } from '@/vendor/HubspotFormIntegration';

import { HubspotFormSimplified } from '@/containers-m/HubspotFormSimplified';

const form_content = {
  title: (
    <>
      Gain access to freight technology, fleet management services, and
      instantly bookable shipments
    </>
  ),
  subtitle: (
    <>
      Our team of industry experts and technicians are here to answer your
      questions.
    </>
  ),
};

export const HubspotFormWrapper = styled.div``;

export const HubspotFormSection = () => (
  <>
    <HubspotFormWrapper id="contact-form">
      <HubspotFormSimplified
        content={form_content}
        {...carrierOptions}
        disclaimer={true}
        theme="dark"
      />
    </HubspotFormWrapper>
  </>
);
