import styled from 'styled-components';

import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const HeroFormWrapper = styled.div`
  margin: auto;
  width: 100%;

  div:nth-child(2) {
    margin: auto -30px;
  }

  ${screen.lg} {
    div:nth-child(2) {
      margin: auto;
    }
  }
`;

export const FormTitle = styled.h2`
  font-family: ${getToken('font-family-title')};
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: rgb(${getToken('color-brand')});
  text-align: center;
  margin: auto;

  ${screen.md} {
    text-align: left;
  }

  ${screen.lg} {
    margin: auto 38px;
  }
`;
