/* eslint-disable @next/next/no-img-element */
//containers + components
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '@/containers-m/Navigation';
import { Container } from '@/components/Grid';
import { Title1 } from '@/components/Text';

// context containers
import { Card } from './containers/';

//styles
import { PageWrapper, ProductsFeatured, TitleSection } from './Login.styles';

//logo
import ShipperGuideLogo from '@/public/images/logos/logo_shipperguide.svg';
import OpendockLogo from '@/public/images/logos/logo_opendock.svg';
import CarrierTMSLogo from '@/public/images/logos/logo_carriertms.svg';

const cards = [
  {
    title: 'SHIPPER GUIDE',
    logo: <ShipperGuideLogo width="225.17" height="30" />,
    description:
      'Multimodal freight procurement & execution tool. Manage rates with any provider of choice, easily tender shipments and share real-time tracking with customers ',
    tag: 'shipper',
    img: {
      src: '/images/login/shipperguide_dash.jpg',
      alt: 'ShipperGuide Dashboard',
    },
    login: {
      url: 'https://accounts.loadsmart.com/auth/login/',
      label: 'Login',
    },
    signup: {
      url: 'https://app.shipper.guide/signup',
      label: 'Signup',
    },
  },
  {
    title: 'CARRIER TMS',
    logo: <CarrierTMSLogo width="208.19" height="30" />,
    description:
      'Multimodal freight procurement & execution tool. Manage rates with any provider of choice, easily tender shipments and share real-time tracking with customers ',
    tag: 'carrier',
    img: {
      src: '/images/login/carrier_dash.jpg',
      alt: 'Carrier Dashboard',
    },
    login: {
      url: 'https://app.carriertms.io/login',
      label: 'Login',
    },
    signup: {
      url: 'https://app.carriertms.io/login?state=checkAccount',
      label: 'Signup',
    },
  },
  {
    title: 'OPENDOCK LOADBOARD',
    logo: <OpendockLogo width="196" height="30" />,
    description:
      'Multimodal freight procurement & execution tool. Manage rates with any provider of choice, easily tender shipments and share real-time tracking with customers ',
    tag: 'warehouse',
    img: {
      src: '/images/login/opendock_dash_02.jpg',
      alt: 'Opendock Dashboard',
    },
    login: {
      url: 'https://nova.opendock.com/login',
      label: 'Login',
    },
    signup: {
      url: 'https://opendock.com/#request-a-demo',
      label: 'Request a Demo',
    },
  },
  {
    title: 'CARRIER LOADBOARD',
    logo: null,
    description:
      'Multimodal freight procurement & execution tool. Manage rates with any provider of choice, easily tender shipments and share real-time tracking with customers ',
    tag: 'carrier',
    img: {
      src: '/images/login/alice_dash.jpg',
      alt: 'Carrier Loadboard',
    },
    login: {
      url: 'https://carrier.loadsmart.com/#/login?href=%2Floads',
      label: 'Login',
    },
    signup: {
      url: 'https://carrier.loadsmart.com/#/signup',
      label: 'Signup',
    },
  },
  {
    title: 'CARRIER OPENDOCK',
    logo: null,
    description:
      'Multimodal freight procurement & execution tool. Manage rates with any provider of choice, easily tender shipments and share real-time tracking with customers ',
    tag: 'carrier',
    img: {
      src: '/images/login/opendock_dash_01.jpg',
      alt: 'Carrier Loadboard',
    },
    login: {
      url: 'https://schedule.opendock.com/login',
      label: 'Login',
    },
    signup: {
      url: 'https://schedule.opendock.com/register',
      label: 'Signup',
    },
  },
  {
    title: 'GATE AND YARD VISIBILITY',
    logo: null,
    description:
      'Camera-based asset tracking software for greater yard visibility, an all-in-one automated yard management solution',
    tag: 'warehouse',
    img: {
      src: '/images/login/carrier_dash.jpg',
      alt: 'Carrier Loadboard',
    },
    login: {
      url: 'https://yms.navtrac.com/auth',
      label: 'Login',
    },
    signup: {
      url: 'https://www.navtrac.com/#wf-form-Get-In-Touch-Form',
      label: 'Request a Demo',
    },
  },
];

const Login = () => {
  return (
    <PageWrapper>
      <Navigation />
      <>
        <Container>
          <TitleSection>
            <Title1>
              Everything you need to move more with less, in one place
            </Title1>
          </TitleSection>
          <ProductsFeatured>
            {cards &&
              cards.map((item, key) => {
                return <Card key={key + '-login-item'} content={item} />;
              })}
          </ProductsFeatured>
        </Container>
      </>
      <Footer />
    </PageWrapper>
  );
};

export default Login;
export { Login };
