//containers
import { HubspotFormRaw } from '@/containers-m/HubspotFormRaw';

//options
import { carrierTMSHeroOptions } from '@/vendor/HubspotFormIntegration';

//styles
import { HeroFormWrapper, FormTitle } from './HubspotHeroFormSection.styles';

export const HubspotHeroFormSection = () => (
  <HeroFormWrapper>
    <FormTitle>Talk to an expert and schedule your demo.</FormTitle>
    <HubspotFormRaw {...carrierTMSHeroOptions}></HubspotFormRaw>
  </HeroFormWrapper>
);
