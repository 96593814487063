import styled, { keyframes } from 'styled-components';

import { screen } from '@/helpers/screen';

//components
import { Container } from 'components/Grid';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const FeaturesCarouselWrapper = styled.section`
  width: 100%;
  padding: 30px 0 20px;
  overflow: hidden;

  background: rgb(${getToken('color-background-primary')});
  text-align: center;

  ${screen.md} {
    padding: 80px 0 30px;
  }
  ${screen.lg} {
    padding: 70px 0 45px;
  }
  ${screen.xxlg} {
    padding: 94px 0 50px;
  }
`;

export const Title = styled.h1`
  margin-bottom: 24px;
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-bold')};
  font-size: 28px;
  line-height: 40px;
  text-align: center;
  margin-top: 0;

  ${screen.md} {
    br {
      display: none;
    }
  }

  ${screen.lg} {
    margin-bottom: 50px;

    font-size: 38px;
    line-height: 50px;
  }

  ${screen.xxlg} {
    margin-bottom: 100px;

    font-size: 48px;
    line-height: 72px;
  }
`;

export const SlidesSection = styled.div`
  display: grid;
  height: 200px;
  overflow: hidden;
  row-gap: 200px;

  ${screen.md} {
    height: 275px;
  }

  ${screen.lg} {
    height: 320px;
  }

  ${screen.xxlg} {
    height: 405px;
  }
`;

export const SlidesContainer = styled(Container)`
  position: relative;

  height: 100%;
  margin: auto;
  padding: 0;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    z-index: 10;

    display: block;
    width: calc((100vw - 100%) / 2);
    height: 100%;

    content: '';
  }

  &::before {
    right: 100%;

    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 5%,
      rgba(255, 255, 255, 0.7) 40%,
      transparent 80%
    );
  }

  &::after {
    left: 100%;

    background: linear-gradient(
      -90deg,
      rgba(255, 255, 255, 1) 5%,
      rgba(255, 255, 255, 0.7) 40%,
      transparent 80%
    );
  }
`;

// carousel animation
const inactiveAnimation = keyframes`
 0% { right: 100%; left: auto;

 opacity: 1;}
 100% { right: 200%; left: auto;

 opacity: 0;}
`;

const activeAnimation = keyframes`
 0% { right: auto; left: 100%;  }
 100% { right: auto; left: 0;  }
`;

const beforeAnimation = keyframes`
 0% { right: 0; left: auto;}
 100% { right: 100%; left: auto;}
`;

const afterAnimation = keyframes`
 0% { right: auto; left: 200%;  }
 100% { right: auto; left: 100%;  }
`;

export const SlidesRotator = styled(Container)`
  position: absolute;
  top: 0;
  right: auto;
  left: auto;

  &.inactive,
  &.active,
  &.before,
  &.after {
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: initial;
    animation-fill-mode: forwards;
  }

  &.inactive {
    opacity: 0;

    animation-name: ${inactiveAnimation};
  }

  &.active {
    opacity: 1;

    animation-name: ${activeAnimation};
  }

  &.before {
    opacity: 1;

    animation-name: ${beforeAnimation};
  }

  &.after {
    opacity: 1;

    animation-name: ${afterAnimation};
  }
`;
