import styled from 'styled-components';
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const FloatTeamHeroBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  background-color: rgb(${getToken('color-background-inverted')});
`;

export const Container = styled.div`
  box-sizing: border-box;
  width: calc(100% - 40px);
  max-width: 316px;
  margin-top: -234px;
  padding: 15px 26px;

  background-color: ${({ theme }) => theme.color.neutral.lightest};
  ${screen.md} {
    max-width: 674px;
    margin-top: -150px;
    padding: 40px 19px;
  }
  ${screen.lg} {
    max-width: 1036px;
    margin-top: -258px;
    padding: 36px;
  }
  ${screen.xxlg} {
    max-width: 1460px;
    margin-top: -300px;
    padding: 50px;
  }
`;

export const Title = styled.h1`
  width: 100%;
  margin: 0;
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-bold')};

  font-size: 24px;
  line-height: 36px;
  text-align: center;

  span {
    color: rgb(${getToken('color-brand')});
  }

  ${screen.md} {
    font-size: 28px;
    line-height: 40px;
  }
  ${screen.lg} {
    font-size: 38px;
    line-height: 50px;
  }
  ${screen.xxlg} {
    font-size: 49px;
    line-height: 72px;

    br {
      display: none;
    }
  }
`;

export const Subtitle = styled.p`
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-regular')};
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  margin: 0;
  margin-top: 16px;

  ${screen.md} {
    margin-top: 28px;
  }
  ${screen.lg} {
    margin-top: 36px;

    font-size: 14px;
    line-height: 30px;
  }
  ${screen.xxlg} {
    margin-top: 42px;

    font-size: 21px;
    line-height: 38px;
  }
`;
