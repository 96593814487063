import styled from 'styled-components';

import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const CardWrapper = styled.article`
  width: 100%;
  border-radius: 8px;
  box-shadow: ${getToken('elevation-1')};
  transition: all 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    box-shadow: ${getToken('elevation-2')};
    transition: all 0.2s;
  }

  ${screen.md} {
    width: 312px;
  }

  ${screen.xxlg} {
    width: 440px;
  }
`;

export const MediaHolder = styled.div`
  position: relative;
  min-height: 100px;

  img {
    max-width: 100%;
    height: auto;
  }
`;

export const TagType = styled.span`
  background: rgb(${getToken('color-background-primary')});
  color: rgb(${getToken('color-text-primary')});
  padding: 8px 16px;
  border-radius: 24px;

  position: absolute;
  bottom: 24px;
  left: 26px;

  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;

  box-shadow: ${getToken('elevation-1')};

  ${screen.xxlg} {
    left: 36px;
  }
`;

export const ContentHolder = styled.div`
  margin: 24px 26px;

  ${screen.xxlg} {
    margin: 24px 36px;
  }
`;

export const TitleHolder = styled.h3`
  font-family: ${getToken('font-family-title')};
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
`;

export const Copy = styled.p`
  font-family: ${getToken('font-family-default')};
  font-weight: 200;
  font-size: 14px;
  line-height: 21px;
`;

export const ArrowHolder = styled.span`
  svg {
    fill: rgb(${getToken('color-text-inverted')});
  }
`;

export const CTAHolder = styled.div`
  display: flex;
  margin: 26px;
  gap: 16px;
  justify-content: flex-end;
  padding-top: 24px;
  border-top: solid 2px rgb(${getToken('color-neutral-20')});

  a {
    display: inline-flex;
    align-items: center;
    gap: 2px;

    padding: 8px;
    font-size: 12px;
    height: fit-content;
    border-width: 1px;

    ${screen.xxlg} {
      padding: 12px 14px;
      font-size: 14px;
      border-width: 2px;
    }

    span {
      display: flex;
    }
  }

  ${screen.xxlg} {
    margin: 36px;
    gap: 24px;
  }
`;
