/* eslint-disable @next/next/no-img-element */
import React from 'react';

// styles
import { SlidePanel, SystemImage } from './Slide.styles';

export function Slide({ property }) {
  return (
    <SlidePanel>
      <SystemImage>
        <img
          src={`${property.image.src}`}
          alt={property.image.alt}
          width="1096"
          height="422"
        />
      </SystemImage>
    </SlidePanel>
  );
}
