// components
import { Container } from 'components/Grid';

// styles
import {
  IntroductionTextWrapper,
  Title,
  Content,
  CTAHolder,
} from './IntroductionText.styles';
import { MirandaBrandButton } from '@/components/Button';

export function IntroductionText() {
  return (
    <IntroductionTextWrapper>
      <Container>
        <Title>
          Carry Your Carrier Into The Digital Age
          <br /> With Our Transportation Management System.
        </Title>
        <Content>
          Traditional TMS manage loads but leave task scheduling largely in the
          hands of users, creating inefficiencies and missed opportunities. By
          adopting a task and workflow management approach, carriers can ensure
          that employees receive task prompts at the right time, with
          streamlined processes that enhance execution speed and accuracy. See
          what makes Loadsmart’s CarrierTMS different.
        </Content>
        <CTAHolder>
          <MirandaBrandButton as="a" href="#hubspot-form">
            Request a demo
          </MirandaBrandButton>
        </CTAHolder>
      </Container>
    </IntroductionTextWrapper>
  );
}
