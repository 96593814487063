/* eslint-disable @next/next/no-img-element */
import {
  MirandaSecondaryButton,
  MirandaHollowSecondaryButton,
} from '@/components/Button';

// styles
import {
  CardWrapper,
  MediaHolder,
  ContentHolder,
  TitleHolder,
  Copy,
  CTAHolder,
  TagType,
  ArrowHolder,
} from './Card.styles';

import Arrow from '@/public/images/icons/icon-arrow.svg';

function cardType(type) {
  switch (type) {
    case 'carrier':
      return 'For Carriers';
    case 'shipper':
      return 'For Shippers';
    case 'warehouse':
      return 'For Warehouses';
    default:
      return '';
  }
}

export function Card({ content }) {
  return (
    <CardWrapper>
      <div>
        <MediaHolder>
          {content.img && <img src={content.img.src} alt={content.img.alt} />}
          <TagType>{cardType(content.tag)}</TagType>
        </MediaHolder>

        <ContentHolder>
          <TitleHolder>
            {content.logo ? content.logo : content.title}
          </TitleHolder>
          <Copy>{content.description}</Copy>
        </ContentHolder>
      </div>

      <CTAHolder>
        <MirandaHollowSecondaryButton
          variant="small"
          as="a"
          href={content.signup.url}
        >
          {content.signup.label}
        </MirandaHollowSecondaryButton>
        <MirandaSecondaryButton variant="small" as="a" href={content.login.url}>
          {content.login.label}
          <ArrowHolder>
            <Arrow width="16" height="16" />
          </ArrowHolder>
        </MirandaSecondaryButton>
      </CTAHolder>
    </CardWrapper>
  );
}
