// styles
import { useEffect, useState } from 'react';
import {
  HeroWrapper,
  HeroContainer,
  CarrierTMSLogo,
  StyledTitle,
  StyledDescription,
} from './Hero.styles';

import { HubspotHeroFormSection } from '../HubspotHeroFormSection/HubspotHeroFormSection';

const arr = ['utm_campaign', 'utm_content', 'utm_medium', 'utm_source'];

function getUrlVars(url) {
  const vars = {};
  if (url.search) {
    url.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      if (arr.includes(key)) {
        vars[key] = value;
      }
    });
  }

  return vars;
}

export function Hero() {
  const [utmValues, setUtmValues] = useState(
    'utm_source=salescollateral&utm_medium=hyperlink&utm_campaign=carrier-sign-up-campaign-carrier-carriertms-q3-24'
  );

  useEffect(() => {
    const queryHref = window.location;
    const findUtms = getUrlVars(queryHref);
    if (
      findUtms.hasOwnProperty('utm_source') &&
      findUtms.hasOwnProperty('utm_medium') &&
      findUtms.hasOwnProperty('utm_campaign')
    ) {
      if (
        findUtms.utm_source === 'capterra' &&
        findUtms.utm_medium === 'profile-page-cta' &&
        findUtms.utm_campaign === 'gartner-ppc-profile-carrier-carriertms-q4-24'
      ) {
        setUtmValues(
          'utm_source=capterra&utm_medium=profile-page-cta&utm_campaign=gartner-ppc-profile-carrier-carriertms-q4-24'
        );
      }
    }
  }, []);

  return (
    <HeroWrapper>
      <HeroContainer>
        <div>
          <CarrierTMSLogo />
          <div>
            <StyledTitle>
              Optimize dispatch.
              <br />
              Reduce overhead costs.
              <br />
              Scale trucking fleets.
              <br />
              All in one TMS.
            </StyledTitle>
            <StyledDescription>
              A truck management software that has all your carrier operations
              and workflow management in one place, making it easier to manage
              all areas within your business.
            </StyledDescription>
          </div>
        </div>
        <HubspotHeroFormSection />
      </HeroContainer>
    </HeroWrapper>
  );
}
