import React from 'react';

// styles
import {
  FloatTeamHeroBoxWrapper,
  Container,
  Title,
  Subtitle,
} from './FloatTeamHeroBox.styles';

export function FloatTeamHeroBox() {
  return (
    <>
      <FloatTeamHeroBoxWrapper>
        <Container>
          <Title>
            Not just technology, but industry <br /> experts and great people
            behind it all. <br />
            <span> Meet the team: the nerds of logistics.</span>
          </Title>
          <Subtitle>
            Keep your trucks full, optimize your back-office operations, and
            instantly bid on contract opportunities through Loadsmart’s agnostic
            hub of loads, services, and integrations. Whether you want to
            utilize our 2.5% factoring, implement a truck management system, or
            get direct access to reliable shipper loads - Loadsmart strives to
            help you save time and money, no matter the market condition.
          </Subtitle>
        </Container>
      </FloatTeamHeroBoxWrapper>
    </>
  );
}
