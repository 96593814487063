import styled, { keyframes } from 'styled-components';

import { screen } from '@/helpers/screen';

// components
import { Container } from 'components/Grid';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const PropertiesCarouselWrapper = styled.section`
  width: 100%;
  padding-bottom: 40px;
  overflow: hidden;

  text-align: center;

  background-color: rgba(${getToken('color-background-primary')});

  ${screen.md} {
    padding: 30px 0;
  }
  ${screen.lg} {
    padding: 45px 0;
  }
  ${screen.xxlg} {
    padding: 50px 0;
  }
`;

//

export const ContentNavigation = styled.div`
  display: flex;
  flex-direction: column;

  ${screen.md} {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  margin-top: 24px;

  color: rgb(${getToken('color-text-primary')});

  a {
    color: rgb(${getToken('color-text-primary')});
  }

  ${screen.md} {
    max-width: 158px;

    cursor: pointer;

    &.inactive {
      color: rgb(${getToken('color-text-placeholder')});

      transition: all 0.1s ease-in-out;

      a {
        color: rgb(${getToken('color-text-placeholder')});

        transition: all 0.1s ease-in-out;
      }
    }

    &.active,
    &:hover {
      color: rgb(${getToken('color-text-primary')});

      transition: all 0.2s ease-in-out;
      a {
        color: rgb(${getToken('color-text-primary')});

        transition: all 0.2s ease-in-out;
      }
    }
  }
  ${screen.lg} {
    max-width: 230px;
  }
  ${screen.xxlg} {
    max-width: 325px;
  }
`;

export const Content = styled.p`
  margin: 16px 0;
  font-family: ${getToken('font-family-default')};
  font-weight: ${getToken('font-weight-regular')};
  font-size: 12px;
  line-height: 22px;
  text-align: center;

  ${screen.md} {
    margin: 20px 0;
    text-align: start;
  }

  ${screen.lg} {
    margin: 24px 0;

    font-size: 16px;
    line-height: 30px;
  }
  ${screen.xxlg} {
    font-size: 20px;
    line-height: 38px;
  }
`;

export const Subtitle = styled.h2`
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  margin: 0;

  br {
    display: none;
  }

  ${screen.md} {
    text-align: start;

    br {
      display: inline-block;
    }
  }
  ${screen.lg} {
    font-size: 22px;
    line-height: 36px;
  }
  ${screen.xxlg} {
    font-size: 26px;
    line-height: 39px;
  }
`;

export const SystemImage = styled.figure`
  width: 100%;
  height: auto;
  padding: 40px 0 24px;

  text-align: center;
  margin: 0;

  img {
    max-width: 100%;
    height: auto;
    margin: auto;
  }

  ${screen.md} {
    display: none;
  }
`;

export const SlideBar = styled.div`
  display: none;
  max-width: 100%;
  height: 3px;

  background-color: #14d64d;

  transition: all 0.3s ease-in-out 0.2s;

  ${screen.md} {
    display: block;
    max-width: 158px;
  }
  ${screen.lg} {
    max-width: 230px;
  }
  ${screen.xxlg} {
    max-width: 290px;
  }
`;

//

export const SlidesSection = styled.div`
  display: none;
  box-sizing: content-box;

  ${screen.md} {
    display: grid;
    min-height: 250px;
    padding-bottom: 29px;
    margin-bottom: 29px;
    overflow: hidden;
  }
  ${screen.lg} {
    min-height: 471px;
    padding-bottom: 40px;
    margin-bottom: 40px;
  }
  ${screen.xxlg} {
    min-height: 586px;
  }
`;

export const SlidesContainer = styled(Container)`
  position: relative;

  display: none;
  height: 100%;
  margin: auto;
  padding: 0;

  ${screen.md} {
    display: block;
    max-width: 510px;
  }

  ${screen.lg} {
    max-width: 980px;
  }

  ${screen.xxlg} {
    max-width: 1380px;
  }

  &::before,
  &::after {
    position: absolute;
    top: 0;
    z-index: 10;

    width: calc((100vw - 100%) / 2);
    height: 100%;

    content: '';
  }
  &::before {
    right: 100%;

    background: linear-gradient(86.23deg, #ffffff -23%, transparent 67%);
  }
  &::after {
    left: 100%;

    background: linear-gradient(-86.23deg, #ffffff -23%, transparent 67%);
  }
`;

// carousel animation
const inactiveAnimation = keyframes`
 0% { right: 100%; left: auto;

 opacity: 1;}
 100% { right: 200%; left: auto;

 opacity: 0;}
`;

const activeAnimation = keyframes`
 0% { right: auto; left: 100%;  }
 100% { right: auto; left: 0;  }
`;

const beforeAnimation = keyframes`
 0% { right: 0; left: auto;}
 100% { right: 100%; left: auto;}
`;

const afterAnimation = keyframes`
 0% { right: auto; left: 200%;  }
 100% { right: auto; left: 100%;  }
`;

// reverse carousel animation
const inactiveAnimationReverse = keyframes` 
    0% { right: 100%; left: auto;

 opacity: 1;}
    50% { right: 200%; left: auto;

 opacity: 0;}
    100% { right: 300%; left: auto;

 opacity: 1;}
`;

const activeAnimationReverse = keyframes`
    0% { right: auto; left: 100%;

  opacity: 0;}
    50% { right: auto; left: 0;

 opacity: 1; }
    100% { right: auto; left: 0;

 opacity: 1; }
`;

const beforeAnimationReverse = keyframes`
    0% { right: 0; left: auto;

 opacity: 0;}
    50% { right: 100%; left: auto;

 opacity: 1;}
    100% { right: 100%; left: auto;

 opacity: 1;}
`;

const afterAnimationReverse = keyframes`
    0% { right: auto; left: 200%;

  opacity: 0;}
    50% { right: auto; left: 100%;

  opacity: 1;}
    100% { right: auto; left: 100%;

  opacity: 1;}
`;

export const SlidesRotator = styled.div`
  position: absolute;
  top: 0;
  right: auto;
  left: auto;

  width: 100%;

  &.inactive,
  &.active,
  &.before,
  &.after {
    animation-duration: ${(props) => props.time};
    animation-timing-function: ease-in-out;
    animation-iteration-count: initial;
    animation-fill-mode: forwards;
  }

  &.active,
  &.before,
  &.after {
    opacity: 1;
  }

  &.inactive {
    animation-name: ${inactiveAnimation};
  }
  &.active {
    animation-name: ${activeAnimation};
  }
  &.before {
    animation-name: ${beforeAnimation};
  }
  &.after {
    animation-name: ${afterAnimation};
  }

  &.reverse {
    &.inactive {
      animation-name: ${inactiveAnimationReverse};
    }
    &.active {
      animation-name: ${activeAnimationReverse};
    }
    &.before {
      animation-name: ${beforeAnimationReverse};
    }
    &.after {
      animation-name: ${afterAnimationReverse};
    }
  }
`;
