/* eslint-disable @next/next/no-img-element */
import React, { useState, useEffect, useCallback } from 'react';
import classnames from 'classnames';

//helpers
import { sleep } from '@/helpers/sleep';

// components
import { Container } from 'components/Grid';

// styles
import {
  PropertiesCarouselWrapper,
  ContentNavigation,
  ContentWrapper,
  Subtitle,
  Content,
  SlidesSection,
  SlidesRotator,
  SlidesContainer,
  SlideBar,
  SystemImage,
} from './Carousel.styles';

// slides
import { Slide } from './Slide';

const properties = [
  {
    name: 'loadmanagement',
    active: true,
    subtitle: (
      <>
        Easy Load <br /> Management
      </>
    ),
    content: `Easily manage your trucks, trailers, and drivers within Carrier TMS or on our Loads App! See all active trucks, driver details, trailer information, and upload new information whenever needed.`,
    image: {
      src: `/images/carrier-tms/slides/carriertms-01.jpg`,
      alt: 'Carrier TMS, Loads screen',
    },
  },
  {
    name: 'emptytrucks',
    subtitle: <>Capacity and Freight Matching</>,
    content: `Take the most out of your capacity by seeing the best loads for your empty trucks.`,
    image: {
      src: `/images/carrier-tms/slides/carriertms-02.jpg`,
      alt: 'Carrier TMS, Truckboard screen',
    },
  },
  {
    name: 'accountinvoincing',
    subtitle: <>Reporting, Accounting, and Invoicing</>,
    content: `Receive easy-to-understand dashboards around your organization's performance and easily create, update, and send invoices within the same platform.`,
    image: {
      src: `/images/carrier-tms/slides/carriertms-03.jpg`,
      alt: 'Carrier TMS, Reports screen',
    },
  },
  {
    name: 'trucklocations',
    subtitle: <>See all your Loads in One Place</>,
    content: `Always know your truck locations and manage dispatching, documentation, invoicing, and tasks on one page.`,
    image: {
      src: `/images/carrier-tms/slides/carriertms-04.jpg`,
      alt: 'Carrier TMS, Load search screen',
    },
  },
];

let timeout = null;
const interval = 5000;

export function Carousel() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [reverse, setReverse] = useState(false);
  const time = 1;
  const timeloop = `${time}s`;

  const clearTimer = () => {
    window.clearInterval(timeout);
  };

  const changeSlide = useCallback(() => {
    setReverse(false);
    setActiveIndex((activeIndex) => (activeIndex + 1) % properties.length);
  }, []);

  const updateActive = useCallback(
    (current) => {
      if (current !== activeIndex) {
        sleep(time * 400).then(() => {
          clearTimer();
          setReverse(true);
          setActiveIndex(current);
          timeout = window.setInterval(() => {
            changeSlide();
          }, interval);
        });
      }

      return false;
    },
    [activeIndex, changeSlide]
  );

  const testAfter = (val) => {
    if (
      val === activeIndex + 1 ||
      (0 === val && activeIndex + 1 === properties.length)
    ) {
      return true;
    }
    return false;
  };

  const testBefore = (val) => {
    if (
      val === activeIndex - 1 ||
      (0 === activeIndex && val + 1 === properties.length)
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    timeout = window.setInterval(() => {
      changeSlide();
    }, interval);

    return () => {
      clearTimer();
    };
  }, [changeSlide]);

  return (
    <PropertiesCarouselWrapper>
      {/* slider content */}
      <SlidesSection>
        <SlidesContainer>
          {properties.map((item, key) => {
            const indexkey = `slide-${item.name}-${key}`;
            const isvisible = key === activeIndex;
            const isAfter = testAfter(key);
            const isBefore = testBefore(key);

            return (
              <SlidesRotator
                key={indexkey}
                activeIndex={activeIndex}
                className={classnames({
                  active: isvisible,
                  inactive: !isvisible,
                  after: isAfter,
                  before: isBefore,
                  reverse: reverse,
                })}
                time={timeloop}
              >
                <Slide property={item} />
              </SlidesRotator>
            );
          })}
        </SlidesContainer>
      </SlidesSection>

      <Container>
        {/* navigation: content */}
        <SlideBar
          style={
            activeIndex > 0 ? { marginLeft: activeIndex * 25.5 + '%' } : {}
          }
        />
        <ContentNavigation>
          {properties.map((item, key) => {
            const indexkey = `content-${item.name}-${key}`;
            const isvisible = key === activeIndex;
            return (
              <ContentWrapper
                key={indexkey}
                className={classnames({
                  active: isvisible,
                  inactive: !isvisible,
                })}
                onClick={() => {
                  updateActive(key);
                }}
                aria-label={`${item.name} slide`}
              >
                {item.image && (
                  <SystemImage>
                    <img
                      src={`${item.image.src}`}
                      alt={item.image.alt}
                      width="786"
                      height=""
                    />
                  </SystemImage>
                )}

                <Subtitle>{item.subtitle}</Subtitle>
                <Content>{item.content}</Content>
              </ContentWrapper>
            );
          })}
        </ContentNavigation>
      </Container>
    </PropertiesCarouselWrapper>
  );
}
